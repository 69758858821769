import Page from "../components/Page";
import {Box, Button, Card, CardContent, CardHeader, Divider, Grid, Typography} from "@mui/material";
import {
    AssignmentTurnedIn,
    CheckCircleOutline,
    ContactPhone,
    Lock,
    Payment,
    PostAdd,
    Speed,
    SvgIconComponent
} from '@mui/icons-material';
import ReactGA from 'react-ga4';
import {ReactComponent as FaxtailBanner} from './banner.svg';

export default function Home(props: { onNext: () => void }) {
    ReactGA.send({hitType: "pageview", page: '/home'});
    return <Page>
        <Box p={4} mt={3}>
            <Grid container spacing={6} justifyContent="center" alignItems="center">
                <Grid item>
                    <Typography variant="h4" component="h1" color="textPrimary" gutterBottom>
                        Simple Faxing
                    </Typography>
                    <Typography variant="subtitle1" component="h3" color="textSecondary">
                        Send fax online anywhere in North America
                    </Typography>
                </Grid>
                <Grid item>
                    <FaxtailBanner width={300} height={200}/>
                </Grid>
            </Grid>

            <Box p={6}/>

            <Box display="flex" flexDirection="column" alignItems="center">
                <Button size="large"
                        style={{fontSize: "1.2rem"}}
                        variant="contained"
                        color="primary"
                        onClick={props.onNext}>
                    Send Fax
                </Button>
                <Box mt={1}>
                    <Typography variant="caption" component="h3" style={{justifySelf: "center"}}>
                        Only $0.20 per page, no other fees
                    </Typography>
                </Box>
            </Box>

            <Box p={6}/>

            <Grid container spacing={5}>
                {
                    [
                        {
                            title: "Simple",
                            description: [
                                "Pay with PayPal",
                                "No account setup",
                                "No monthly fees",
                            ],
                            icon: AssignmentTurnedIn
                        },
                        {
                            title: "Fast",
                            description: [
                                "Fax in under a minute",
                                "3 automatic retries",
                                "No queueing delay",
                            ],
                            icon: Speed
                        },
                        {
                            title: "Private",
                            description: [
                                "Secure connection",
                                "No file retention",
                                "No ads",
                            ],
                            icon: Lock
                        },
                    ].map((props: { title: string, description: string[], icon: SvgIconComponent }, idx) =>
                        <Grid item xs={12} sm key={idx}>
                            <Card elevation={0}>
                                <CardHeader title={<>
                                    <props.icon color="primary" style={{fontSize: 80}}/>
                                    <Typography variant="h6" component="h2" align="center" color="primary">
                                        {props.title}
                                    </Typography>
                                </>}
                                            titleTypographyProps={{align: 'center'}}/>
                                <Divider variant="middle"/>
                                <CardContent>
                                    <ul style={{
                                        margin: 0,
                                        padding: 0,
                                        listStyle: 'none',
                                    }}>
                                        {props.description.map((el, idx) =>
                                            <Typography component="li"
                                                        variant="subtitle1"
                                                        align="center"
                                                        color="textSecondary"
                                                        key={idx}>
                                                {el}
                                            </Typography>
                                        )}
                                    </ul>
                                </CardContent>
                            </Card>
                        </Grid>)
                }
            </Grid>

            <blockquote>
                <Box my={12}>
                    <Typography variant="h5" color="textPrimary" align="center"
                                style={{justifySelf: "center"}}>
                        Faxtail is designed with security in mind and is GDPR compliant, making it a safe and
                        secure faxing solution
                    </Typography>
                </Box>
            </blockquote>

            <Box my={10}>
                <Grid container spacing={6}>
                    <Grid item xs style={{alignSelf: "center"}}>
                        <Typography variant="h5" component="h1" color="textPrimary" align="center" gutterBottom>
                            Sending a Fax is Easy
                        </Typography>
                    </Grid>
                    {
                        [
                            {
                                title: "1. Enter Fax Number",
                                icon: ContactPhone
                            },
                            {
                                title: "2. Upload Documents",
                                icon: PostAdd
                            },
                            {
                                title: "3. Pay with Paypal",
                                icon: Payment
                            },
                            {
                                title: "4. Done",
                                icon: CheckCircleOutline
                            },
                        ].map((props: { title: string, icon: SvgIconComponent }, idx) =>
                            <Grid item xs={12} sm key={idx}>
                                <Box display="flex" flexDirection="column" alignItems="center">
                                    <props.icon style={{fontSize: 64, textAlign: "center", color: 'orange'}}/>
                                    <Typography variant="body1" align="center" color="textSecondary">
                                        {props.title}
                                    </Typography>
                                </Box>
                            </Grid>)
                    }
                </Grid>
            </Box>
        </Box>
    </Page>;
}
