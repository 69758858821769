import {CSSProperties, ReactNode, useState} from "react";
import {AppBar, Box, Container, Dialog, Link, Toolbar, Typography} from "@mui/material";
import {BACKEND_ROOT_URL} from "../config";
import {Print} from "@mui/icons-material";
// @ts-ignore
import raw from "raw.macro";
import ReactMarkdown from "react-markdown";

export default function Page(props: { children: ReactNode, style?: CSSProperties }) {
    const [open, setOpen] = useState(false);
    const markdown = raw('./PrivacyPolicy.md');

    return <>
        <AppBar position="relative" elevation={1}>
            <Toolbar>
                <Print/>
                <Box mr={1}/>
                <Link href={BACKEND_ROOT_URL} color="inherit" underline="hover">
                    <Typography variant="h6" color="inherit" noWrap>
                        Faxtail
                    </Typography>
                </Link>
            </Toolbar>
        </AppBar>
        <Container maxWidth="md" style={{backgroundColor: 'white', ...props.style}}>
            {props.children}
            <footer>
                <Box my={3}>
                    <Typography variant="body2" color="textSecondary" align="center">

                        {'Contact us at '}
                        <Link
                            variant="body2"
                            href="mailto:support@faxtail.com?subject=Faxtail.com%20support%20request"
                            underline="hover">
                            support@faxtail.com
                        </Link>

                        <br/>

                        {`Copyright ©Faxtail ${new Date().getFullYear()}`}
                        <br/>
                        <Link href="https://blog.faxtail.com/" variant="body2" underline="hover">
                            Blog
                        </Link>
                        &nbsp;
                        &nbsp;
                        <Link variant="body2" onClick={() => setOpen(true)} underline="hover">
                            Privacy Policy
                        </Link>
                    </Typography>
                </Box>

                <Dialog open={open}
                        onClose={() => setOpen(false)}
                        scroll="paper">
                    <Box m={5}>
                        <ReactMarkdown children={markdown}/>
                    </Box>
                </Dialog>
            </footer>
        </Container>
    </>;
}
