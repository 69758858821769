import {PayPalButton} from "react-paypal-button-v2";

export function PaymentComponent(props: {
    orderID: string,
    onSuccess: (details: PayPalDetails, data: PayPalData) => void
}) {
    return <PayPalButton
        options={process.env.NODE_ENV === 'production' ? {clientId: "AbEhtAvJ5RrumocynRyVnwqTxtPLTFYShe7wmD3eUkyoJlayEiGPVFP2DY047gIDABYjYbW0S7_RXO1t"} : undefined}
        onSuccess={props.onSuccess}
        createOrder={() => props.orderID} //skips order creation process, instead uses orderId generated by server
    />;
}

export interface PayPalData {
    orderID: string;
    payerID: string;
    paymentID: null;
    billingToken: null;
    facilitatorAccessToken: string;
}


export interface PayPalDetails {
    create_time: string;
    update_time: string;
    id: string;
    intent: string;
    status: string;
    payer: Payer;
    purchase_units: PurchaseUnit[];
    links: Link[];
}

export interface Link {
    href: string;
    rel: string;
    method: string;
    title: string;
}

export interface Payer {
    email_address: string;
    payer_id: string;
    address: PayerAddress;
    name: PayerName;
}

export interface PayerAddress {
    country_code: string;
}

export interface PayerName {
    given_name: string;
    surname: string;
}

export interface PurchaseUnit {
    reference_id: string;
    amount: Amount;
    payee: Payee;
    shipping: Shipping;
    payments: Payments;
}

export interface Amount {
    value: string;
    currency_code: string;
}

export interface Payee {
    email_address: string;
    merchant_id: string;
}

export interface Payments {
    captures: Capture[];
}

export interface Capture {
    status: string;
    id: string;
    final_capture: boolean;
    create_time: string;
    update_time: string;
    amount: Amount;
    seller_protection: SellerProtection;
    links: Link[];
}

export interface SellerProtection {
    status: string;
    dispute_categories: string[];
}

export interface Shipping {
    name: ShippingName;
    address: ShippingAddress;
}

export interface ShippingAddress {
    address_line_1: string;
    admin_area_2: string;
    admin_area_1: string;
    postal_code: string;
    country_code: string;
}

export interface ShippingName {
    full_name: string;
}

export const mockDetails = {
    "create_time": "2020-02-18T20:07:29Z",
    "update_time": "2020-02-18T20:07:54Z",
    "id": "94639679A1899954E",
    "intent": "CAPTURE",
    "status": "COMPLETED",
    "payer": {
        "email_address": "sb-huttq982026@personal.example.com",
        "payer_id": "UWWH68Z9W8BBW",
        "address": {
            "country_code": "US"
        },
        "name": {
            "given_name": "John",
            "surname": "Doe"
        }
    },
    "purchase_units": [
        {
            "reference_id": "default",
            "amount": {
                "value": "0.10",
                "currency_code": "USD"
            },
            "payee": {
                "email_address": "sb-gushp983256@business.example.com",
                "merchant_id": "R6BX74QCZF7KL"
            },
            "shipping": {
                "name": {
                    "full_name": "John Doe"
                },
                "address": {
                    "address_line_1": "1 Main St",
                    "admin_area_2": "San Jose",
                    "admin_area_1": "CA",
                    "postal_code": "95131",
                    "country_code": "US"
                }
            },
            "payments": {
                "captures": [
                    {
                        "status": "COMPLETED",
                        "id": "38N258911N8410703",
                        "final_capture": true,
                        "create_time": "2020-02-18T20:07:54Z",
                        "update_time": "2020-02-18T20:07:54Z",
                        "amount": {
                            "value": "0.10",
                            "currency_code": "USD"
                        },
                        "seller_protection": {
                            "status": "ELIGIBLE",
                            "dispute_categories": [
                                "ITEM_NOT_RECEIVED",
                                "UNAUTHORIZED_TRANSACTION"
                            ]
                        },
                        "links": [
                            {
                                "href": "https://api.sandbox.paypal.com/v2/payments/captures/38N258911N8410703",
                                "rel": "self",
                                "method": "GET",
                                "title": "GET"
                            },
                            {
                                "href": "https://api.sandbox.paypal.com/v2/payments/captures/38N258911N8410703/refund",
                                "rel": "refund",
                                "method": "POST",
                                "title": "POST"
                            },
                            {
                                "href": "https://api.sandbox.paypal.com/v2/checkout/orders/94639679A1899954E",
                                "rel": "up",
                                "method": "GET",
                                "title": "GET"
                            }
                        ]
                    }
                ]
            }
        }
    ],
    "links": [
        {
            "href": "https://api.sandbox.paypal.com/v2/checkout/orders/94639679A1899954E",
            "rel": "self",
            "method": "GET",
            "title": "GET"
        }
    ]
};


export const mockData = {
    "orderID": "4A795150VY703581A",
    "payerID": "UWWH68Z9W8BBW",
    "paymentID": null,
    "billingToken": null,
    "facilitatorAccessToken": "A21AAEvLbSjt2ch34plLLVk8GAJPojnml8vUJYmEN6ABwoS1uuw0x_MoYHM2bcJxh2f2d_lKniG4KUiNRS7SIfaSE1mNerOTg"
};
