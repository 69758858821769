import {ApolloClient, ApolloProvider, InMemoryCache} from "@apollo/client";
import {PageContainer} from "./PageContainer";
import {GRAPHQL_ENDPOINT} from "./config";
import ReactGA from 'react-ga4';
import * as Sentry from '@sentry/browser';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

Sentry.init({
    dsn: "https://7a522efdfedd40549fafefbd60283368@sentry.io/5185530",
    environment: process.env.NODE_ENV,
    ignoreErrors: ["https://www.paypal.com/xoplatform/logger/api/logger",
        "https://www.sandbox.paypal.com/xoplatform/logger/api/logger"],
    beforeSend(event, hint) {
        // Check if it is an exception, and if so, show the report dialog
        if (event.exception) {
            Sentry.showReportDialog({eventId: event.event_id});
        }
        return event;
    }
});

const client = new ApolloClient({
    cache: new InMemoryCache(),
    uri: GRAPHQL_ENDPOINT
});


const theme = createTheme({
    palette: {
        primary: {
            light: '#757ce8',
            main: '#3f50b5',
            dark: '#002884',
            contrastText: '#fff',
        },
        secondary: {
            light: '#ff4081',
            main: '#f50057',
            dark: '#c51162',
            contrastText: '#000',
        },
    },
});
export default function App() {
    ReactGA.initialize('G-PV200JQ58S', {
        // debug: process.env.NODE_ENV !== 'production',
        gaOptions: {siteSpeedSampleRate: 100}
    });
    return <ApolloProvider client={client}>
        <CssBaseline/>
        <ThemeProvider theme={theme}>
            <PageContainer/>
        </ThemeProvider>
    </ApolloProvider>;
}
