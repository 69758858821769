import {Box, Step, StepLabel, Stepper} from "@mui/material";
import {PageToDisplay} from "../config";

export function ProgressStepperUnstyled(props: { activeStep: PageToDisplay }) {
    function activeStepToIndex(activeStep: PageToDisplay): number {
        return activeStep - 1;
    }

    const steps = ["Fax Number", "Upload Documents", "Review", "Receipt"];
    return <Stepper activeStep={activeStepToIndex(props.activeStep)} alternativeLabel>
        {steps.map(label => (
            <Step key={label}>
                <StepLabel>{label}</StepLabel>
            </Step>
        ))}
    </Stepper>;
}

export function ProgressStepper(props: { activeStep: PageToDisplay }) {

    return <Box m={3}>
        <ProgressStepperUnstyled activeStep={props.activeStep}/>
    </Box>;
}
