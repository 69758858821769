/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
};

export enum FaxState {
  Completed = 'COMPLETED',
  Error = 'ERROR',
  Sending = 'SENDING'
}

export type Mutation = {
  __typename?: 'Mutation';
  /**
   * uploadTransactionId is created by getUploadTransactionId()
   * it is used for finding the uploaded files associated with this fax
   * returns null if phone number is invalid or txn id not found
   */
  fax?: Maybe<Order>;
};


export type MutationFaxArgs = {
  to: Scalars['String']['input'];
  uploadTransactionId: Scalars['ID']['input'];
};

export type Order = {
  __typename?: 'Order';
  cost: Scalars['String']['output'];
  /** Price is in the format of numbers followed by 2 decimal places */
  id: Scalars['ID']['output'];
  numPages: Scalars['Int']['output'];
};

export type Query = {
  __typename?: 'Query';
  getFaxStatus?: Maybe<Status>;
  getUploadTransactionId: Scalars['ID']['output'];
};


export type QueryGetFaxStatusArgs = {
  id: Scalars['ID']['input'];
};

export type Status = {
  __typename?: 'Status';
  faxState: FaxState;
  id: Scalars['ID']['output'];
  percentComplete?: Maybe<Scalars['Int']['output']>;
};

export type GetUploadTransactionIdQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUploadTransactionIdQuery = { __typename?: 'Query', getUploadTransactionId: string };

export type SendFaxMutationVariables = Exact<{
  to: Scalars['String']['input'];
  uploadTransactionId: Scalars['ID']['input'];
}>;


export type SendFaxMutation = { __typename?: 'Mutation', fax?: { __typename?: 'Order', id: string, numPages: number, cost: string } | null };


export const GetUploadTransactionIdDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetUploadTransactionId"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getUploadTransactionId"}}]}}]} as unknown as DocumentNode<GetUploadTransactionIdQuery, GetUploadTransactionIdQueryVariables>;

/**
 * __useGetUploadTransactionIdQuery__
 *
 * To run a query within a React component, call `useGetUploadTransactionIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUploadTransactionIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUploadTransactionIdQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUploadTransactionIdQuery(baseOptions?: Apollo.QueryHookOptions<GetUploadTransactionIdQuery, GetUploadTransactionIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUploadTransactionIdQuery, GetUploadTransactionIdQueryVariables>(GetUploadTransactionIdDocument, options);
      }
export function useGetUploadTransactionIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUploadTransactionIdQuery, GetUploadTransactionIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUploadTransactionIdQuery, GetUploadTransactionIdQueryVariables>(GetUploadTransactionIdDocument, options);
        }
export type GetUploadTransactionIdQueryHookResult = ReturnType<typeof useGetUploadTransactionIdQuery>;
export type GetUploadTransactionIdLazyQueryHookResult = ReturnType<typeof useGetUploadTransactionIdLazyQuery>;
export type GetUploadTransactionIdQueryResult = Apollo.QueryResult<GetUploadTransactionIdQuery, GetUploadTransactionIdQueryVariables>;
export const SendFaxDocument = gql`
    mutation SendFax($to: String!, $uploadTransactionId: ID!) {
  fax(to: $to, uploadTransactionId: $uploadTransactionId) {
    id
    numPages
    cost
  }
}
    `;
export type SendFaxMutationFn = Apollo.MutationFunction<SendFaxMutation, SendFaxMutationVariables>;

/**
 * __useSendFaxMutation__
 *
 * To run a mutation, you first call `useSendFaxMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendFaxMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendFaxMutation, { data, loading, error }] = useSendFaxMutation({
 *   variables: {
 *      to: // value for 'to'
 *      uploadTransactionId: // value for 'uploadTransactionId'
 *   },
 * });
 */
export function useSendFaxMutation(baseOptions?: Apollo.MutationHookOptions<SendFaxMutation, SendFaxMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendFaxMutation, SendFaxMutationVariables>(SendFaxDocument, options);
      }
export type SendFaxMutationHookResult = ReturnType<typeof useSendFaxMutation>;
export type SendFaxMutationResult = Apollo.MutationResult<SendFaxMutation>;
export type SendFaxMutationOptions = Apollo.BaseMutationOptions<SendFaxMutation, SendFaxMutationVariables>;